import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function FindPostCode() {
    const [postCode, setPostCode] = useState('');  // State for holding post code input
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(null); // Track validation status
    const handleNavigate = () => {
        if (postCode) {
            navigate('/GetPostCode', {
                state: {
                    postCode: postCode,
                },
            });
        } else {
            alert('Please enter a post code.');
        }
    };
    const validatePostalCode = (code) => {
        const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;
        return regex.test(code);
    };
    const handleChange = (e) => {
        const code = e.target.value;
        setPostCode(code);

        if (validatePostalCode(code)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };
    return (
        <div>
            <div className="main bg-light">
                <div className="homeContainer pb-5">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 text-center">
                                        <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='bookOnlineBox5'>
                            <div className='HalfOne'>
                                <img src='images/car22.jpg' alt="Car" />
                            </div>
                            <div className='HalfTwo'>

                                <h2>Welcome</h2>
                                <h5 className='mt-3'>Enter Your Post Code here</h5>
                                {/* Input field for entering post code */}
                                <div className='container-fluid'>
                                    <input
                                        type="text"
                                        value={postCode}
                                        onChange={handleChange} // Validation logic added here
                                        placeholder="Enter Post Code"
                                        className="form-control mt-3"
                                    />

                                    {/* Conditional rendering based on validation */}
                                    {isValid === false && <p style={{ color: 'red' }}>Invalid Postal Code.</p>}
                                    {isValid === true && <p style={{ color: 'green' }}>Valid postal code!</p>}
                                    {/* Button to navigate */}
                                </div>
                                <button
                                    onClick={handleNavigate}
                                    className="btn btn-primary mt-3"
                                    disabled={!isValid} // Disable button if postal code is invalid
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FindPostCode;
