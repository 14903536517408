import React, { useState, useRef, useEffect } from 'react';
import ROUTES from '../../navigations/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import axios from 'axios';
import { Link } from 'react-router-dom';

function OfflineStudentRegistration() {
    const location = useLocation();
    const navigate = useNavigate();
    const handleNavigateback = () => {
        navigate('/GetPostCode', {
            state: {
                postCode: postCode,
            },
        });

    };

    const { instructorId, pricing, gearBoxName, hours, date, time, scheduleId, postCode } = location.state || {};
    const [isBillingSameAsPickup, setIsBillingSameAsPickup] = useState(true);
    const [isGift, setIsGift] = useState(false);
    const [isSurprice, setIsSurprice] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [instructorData, setInstructorData] = useState(null);

    useEffect(() => {
        console.log(instructorId)
        if (instructorId) {
            fetch(`https://csmserver.csmdrivingschool.ca/Instructor.php?id=${instructorId}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setInstructorData(data);

                })
                .catch((error) => {
                    console.error('Error fetching instructor data:', error);
                });
        }
    }, [instructorId]);


    const initialValues = {
        userName: '',
        email: '',
        dob: null,
        postalCode: '',
        phoneNo: '',
        pickupAddress: '',
        billingAddress: '',
        emailActive: true,
        smsActive: true,
        callActive: true,
        postActive: true,
        studentType: 'Offline',
        isGift: isGift,
        isSurprise: isSurprice,
        gifterEmail: '',
        gifterPhoneNo: '',
        instructorId: instructorId,
        selectedPrice: pricing,
        selectedGear: gearBoxName,
        selectedHours: hours,
    };
    const sendEmail = (values) => {
        console.log("Reciever Email", values.email)
        const formData = {
            from_name: 'CSM Driving School',
            to_name: values.userName,
            to_email: values.email,
            message: values.message,
        };

        emailjs.send(
            'service_adq89ea',
            'template_cdkoc5r',
            formData,
            'oI8Ox58skDQ10Em0z'
        )
    };




    const validationSchema = Yup.object({
        userName: Yup.string().required('Username is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        dob: Yup.date().required('Date of Birth is required'),
        phoneNo: Yup.string().required('Phone Number is required'),
        pickupAddress: Yup.string().required('Pickup Address is required'),
    });

    const onSubmit = async (values) => {
        try {
            const response = await fetch('https://csmserver.csmdrivingschool.ca/StudentRegister.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (response.ok) {

                const newId = await fetch('https://csmserver.csmdrivingschool.ca/GetNewStudentId.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Parse the response as JSON
                const data = await newId.json();

                // Assuming your PHP API returns a JSON object with the ID as `max_id`
                // console.log('Registration Successful, ID:', generatedId);
                const generatedId = data.max_id;

                //
                //
                //
                // await sendEmail(values);
                //
                //
                //

                console.log(values.gifterEmail)
                const secondApiData = {
                    studentRegisterId: generatedId,
                    instructorId: instructorId,
                    selectedPrice: pricing,
                    selectedGear: gearBoxName,
                    selectedHours: hours,
                    scheduleId: scheduleId,
                    isGift: isGift,
                    isSurprise: isSurprice,
                    gifterEmail: values.gifterEmail,
                    gifterPhoneNo: values.gifterPhoneNo,
                };
                // Log the data before sending
                // Now use the generated ID for the second API call
                const secondApiResponse = await fetch('https://csmserver.csmdrivingschool.ca/OfflineStudentRequest.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(secondApiData), // Second API call
                });


                if (secondApiResponse.ok) {
                    console.log(`Data added to the second table successfully ${generatedId}`);
                } else {
                    throw new Error('Failed to add data to the second table');
                }
                navigate('/StudentRegisterSummary', {
                    state: {
                        regId: generatedId,
                    },
                });
            } else {
                throw new Error('First API network response was not ok.');
            }
        } catch (error) {
            console.error('Error while submitting', error);
        }
    };
    const handleBack = (event) => {
        event.preventDefault(); // Prevent the default anchor tag behavior
        window.history.back(); // Go back to the previous page
    };




    return (
        <div className="main bg-light">
            <div className="homeContainer pb-5">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <>
                                {/* <div className="container mt-5">
                                    <h3>Instructor ID: {instructorId}</h3>
                                    <h3>Pricing: {pricing}</h3>
                                    <h3>Gear Box: {gearBoxName}</h3>
                                    <h3>Hours: {hours}</h3>
                                    <h3>Selected Id: {scheduleId}</h3>
                                </div> */}
                                <div className="bookOnlineBox5">
                                    <div className="HalfOne">
                                        <div className='invoice2'>

                                            {instructorData ? (
                                                <>
                                                    <h2>Instructor Details</h2>
                                                    <p>First Name : {instructorData.firstName} {instructorData.lastName}</p>
                                                    <p>Pay rate : ${instructorData.payRate}</p>

                                                    {/* Add other properties as needed */}
                                                </>
                                            ) : (
                                                <p>Loading instructor data...</p>
                                            )}
                                            <h3>Program Details</h3>

                                            <p>Selected Hours : {hours}</p>
                                            <p>Gear Box : {gearBoxName}</p>
                                            <p className='text-success'>Pricing : ${pricing}</p>
                                            <b className='text-success'>Total Price : ${pricing}</b>
                                            <button className='btn btn-primary' onClick={handleNavigateback}>Change Program</button>
                                        </div>
                                        {/* <img src="images/car22.jpg" alt="Car" /> */}
                                    </div>
                                    <div className="HalfTwo">
                                        <div className='w-100 px-4 btn-house'>
                                            <div className='container'>
                                                <a className='btn-back' href="#" onClick={handleBack}><i class="fa-solid fa-arrow-left"></i></a>
                                            </div>
                                        </div>
                                        <div className="innerHalf">


                                            <div className="instructor-info text-center">
                                                <h3 className="mb-2 text-center">Now we just need the student's details</h3>
                                                <div className="container-fluid">

                                                    {/* Formik Form */}

                                                    <div className="row ">
                                                        {/* Username Field */}
                                                        <div className="col-md-12">
                                                            <div className="formGroup">
                                                                <div className="mant">

                                                                    <ErrorMessage name="userName" component="span" style={{ color: 'red' }} />
                                                                </div>
                                                                <Field
                                                                    type="text"
                                                                    className="formControl border-1 "
                                                                    id="userName"
                                                                    placeholder="Student Name"
                                                                    name="userName"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Email Field */}
                                                        <div className="col-md-12">
                                                            <div className="formGroup">
                                                                <div className="mant">
                                                                    <ErrorMessage name="email" component="span" style={{ color: 'red' }} />
                                                                </div>
                                                                <Field
                                                                    type="email"
                                                                    className="formControl border-1 "
                                                                    id="email"
                                                                    placeholder="Student Email"
                                                                    name="email"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Date of Birth Field */}
                                                        <div className="col-md-6">
                                                            <div className="formGroup">
                                                                <div className="mant">
                                                                    <ErrorMessage name="dob" component="span" style={{ color: 'red' }} />
                                                                </div>
                                                                <Field
                                                                    type="date"
                                                                    className="formControl border-1 "
                                                                    id="dob"
                                                                    placeholder="Date of Birth"
                                                                    name="dob"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Phone Number Field */}
                                                        <div className="col-md-6">
                                                            <div className="formGroup">
                                                                <div className="mant">
                                                                    <ErrorMessage name="phoneNo" component="span" style={{ color: 'red' }} />
                                                                </div>
                                                                <Field
                                                                    type="text"
                                                                    className="formControl border-1 "
                                                                    id="phoneNo"
                                                                    placeholder="Phone Number"
                                                                    name="phoneNo"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Pickup Address Field */}
                                                        <div className="col-md-12">
                                                            <div className="formGroup">
                                                                <div className="mant">
                                                                    <ErrorMessage name="pickupAddress" component="span" style={{ color: 'red' }} />
                                                                </div>
                                                                <Field
                                                                    type="text"
                                                                    className="formControl border-1 "
                                                                    id="pickupAddress"
                                                                    placeholder="Pickup Address Postal Code"
                                                                    name="pickupAddress"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Billing Address Field */}
                                                        <div className="col-md-12 mt-3">
                                                            {/* Yes/No button to toggle billing address visibility */}
                                                            <div className="formGroup">
                                                                <label>Is your billing address the same as pickup?</label>
                                                                <div className='d-flex'>
                                                                    <button
                                                                        type="button"
                                                                        className={`mr-2 ${isBillingSameAsPickup ? 'btnHollowActive' : 'btnHollow'}`}
                                                                        onClick={() => setIsBillingSameAsPickup(true)}
                                                                    >
                                                                        Yes
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className={`ml-2  ${!isBillingSameAsPickup ? 'btnHollowActive' : 'btnHollow'}`}
                                                                        onClick={() => setIsBillingSameAsPickup(false)}
                                                                    >
                                                                        No
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {/* Conditional rendering of billing address field */}
                                                            {!isBillingSameAsPickup && (

                                                                <div className="formGroup">
                                                                    <div className="mant">
                                                                        <ErrorMessage name="billingAddress" component="span" style={{ color: 'red' }} />
                                                                    </div>
                                                                    <Field
                                                                        type="text"
                                                                        className="formControl border-1"
                                                                        id="billingAddress"
                                                                        placeholder="Billing Postal Code"
                                                                        name="billingAddress"
                                                                        autoComplete="off"
                                                                    />
                                                                </div>

                                                            )}
                                                        </div>





                                                        <div className="col-md-12">
                                                            <div className="formGroup">

                                                                <Field
                                                                    type="hidden"
                                                                    id="studentType"
                                                                    name="studentType"
                                                                    value="offline"

                                                                />
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bookOnlineBox3">
                                    <div className="row px-4">
                                        <div className="col-12 col-lg-5 px-4 py-3 text-left mt-3">
                                            <h4>
                                                Stay in the know
                                            </h4>
                                            <p className='text-justify'>
                                                We would like to contact you from time to time about driving advice and offers via email, SMS and phone. You can opt out at any time – see our<a> privacy notice </a>to find out how. Choose how we contact you.
                                            </p>
                                            <div className="d-flex w-100 g-3 mt-4">
                                                <div className="col-md-3 p-0">
                                                    <div className="form-check dFlex1">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input toggle-switch"
                                                            id="emailActive"
                                                            name="emailActive"

                                                        />
                                                        <label htmlFor="emailActive" className="form-check-label ">Email</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 p-0">
                                                    <div className="form-check dFlex1">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input toggle-switch"
                                                            id="smsActive"
                                                            name="smsActive"

                                                        />
                                                        <label htmlFor="smsActive" className="form-check-label">SMS</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 p-0">
                                                    <div className="form-check form-switch dFlex1 ">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input toggle-switch"
                                                            id="callActive"
                                                            name="callActive"
                                                        />
                                                        <label htmlFor="callActive" className="form-check-label">Call</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 p-0">
                                                    <div className="form-check form-switch dFlex1 ">
                                                        <Field
                                                            type="checkbox"
                                                            className="form-check-input toggle-switch"
                                                            id="postActive"
                                                            name="postActive"
                                                        />
                                                        <label htmlFor="postActive" className="d-block">Post</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {!isGift || !isSurprice && (
                                                <div className='refundMsg2 mt-3 row py-4 px-1'>
                                                    <div className='col-1 py-2'>

                                                        <i class="fa-solid fa-triangle-exclamation fa-2x"></i>
                                                    </div>
                                                    <div className='col-11 px-4 py-1 d-flex flex-column'>
                                                        <h6>This gift will NOT be a surprise</h6>
                                                        <p>The learner will be notified the details of their lessons.</p>
                                                    </div>
                                                </div>
                                            )}

                                            {!isGift || isSurprice && (
                                                <div className='refundMsg2 mt-3 row py-4 px-1'>
                                                    <div className='col-1 py-2'>

                                                        <i class="fa-solid fa-triangle-exclamation fa-2x"></i>
                                                    </div>
                                                    <div className='col-11 px-4 py-1 d-flex flex-column'>
                                                        <h6>Don't worry, we won't tell the learner about your surprise gift</h6>
                                                        <p>We'll send you any lesson details and updates until the learner logs into their account for the first time.</p>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div className="col-12 col-md-7 text-left mt-3">
                                            <div className="col-12 mb-3  p-0 text-center">
                                                <div className="col-md-12  mt-3">

                                                    <div className="formGroup">
                                                        <label><i style={{ color: "#8A1DDE" }} class="fa-solid fa-gift"></i> Is this a gift?</label>
                                                        <div className='d-flex'>
                                                            <button
                                                                type="button"
                                                                className={`mr-2 ${isGift ? 'btnHollowActive' : 'btnHollow'}`}
                                                                onClick={() => setIsGift(true)}
                                                            >
                                                                Yes
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={`ml-2  ${!isGift ? 'btnHollowActive' : 'btnHollow'}`}
                                                                onClick={() => { setIsGift(false); setIsSurprice(false) }}
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {/* Conditional rendering of billing address field */}
                                                    {isGift && (




                                                        <div className="formGroup mt-3">
                                                            <label>Would you like these lessons to be a surprise to the learner?</label>
                                                            <div className='d-flex'>
                                                                <button
                                                                    type="button"
                                                                    className={`mr-2 ${isSurprice ? 'btnHollowActive' : 'btnHollow'}`}
                                                                    onClick={() => setIsSurprice(true)}
                                                                >
                                                                    Yes
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`ml-2  ${!isSurprice ? 'btnHollowActive' : 'btnHollow'}`}
                                                                    onClick={() => setIsSurprice(false)}
                                                                >
                                                                    No
                                                                </button>
                                                            </div>



                                                            {isSurprice && (
                                                                <>
                                                                    <div className="formGroup">
                                                                        <div className="mant">
                                                                            <ErrorMessage name="gifterEmail" component="span" style={{ color: 'red' }} />
                                                                        </div>
                                                                        <Field
                                                                            type="text"
                                                                            className="formControl border-1"
                                                                            id="gifterEmail"
                                                                            placeholder="Your Email"
                                                                            name="gifterEmail"
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                    <div className="formGroup">
                                                                        <div className="mant">
                                                                            <ErrorMessage name="gifterPhoneNo" component="span" style={{ color: 'red' }} />
                                                                        </div>
                                                                        <Field
                                                                            type="text"
                                                                            className="formControl border-1"
                                                                            id="gifterPhoneNo"
                                                                            placeholder="Your Phone No."
                                                                            name="gifterPhoneNo"
                                                                            autoComplete="off"

                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <p className="mt-4 text-left">
                                                    CSM terms and conditions
                                                </p>
                                                <div className="form-check termsBox">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input mt-2"
                                                        id="termsAccepted"
                                                        checked={termsAccepted}
                                                        onChange={() => setTermsAccepted(!termsAccepted)} // Toggle termsAccepted state
                                                    />
                                                    <label className="form-check-label" htmlFor="termsAccepted">
                                                        I have read, understood, and accept the
                                                        <Link target='_blank' to={ROUTES.termsandconditions.name} className=""> terms and conditions </Link>
                                                    </label>
                                                </div>
                                                <button type="submit" disabled={!termsAccepted} className="btn btn-primary mt-4 mb-3">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
}
export default OfflineStudentRegistration;
