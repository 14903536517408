import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import ROUTES from './Routes';

function Navigation() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.index.name} element={ROUTES.index.component}>
            <Route
              path={ROUTES.home.name}
              element={ROUTES.home.component}
            ></Route>
            <Route
              path={ROUTES.studentRegisterSummary.name}
              element={ROUTES.studentRegisterSummary.component}
            ></Route>
            <Route
              path={ROUTES.findPostCode.name}
              element={ROUTES.findPostCode.component}
            ></Route>
            <Route
              path={ROUTES.getPostCode.name}
              element={ROUTES.getPostCode.component}
            ></Route>
            <Route
              path={ROUTES.offlineStudentRegistration.name}
              element={ROUTES.offlineStudentRegistration.component}
            ></Route>
            <Route
              path={ROUTES.getTime.name}
              element={ROUTES.getTime.component}
            ></Route>
            <Route
              path={ROUTES.about.name}
              element={ROUTES.about.component}
            ></Route>
            <Route
              path={ROUTES.contact.name}
              element={ROUTES.contact.component}
            ></Route>
            <Route
              path={ROUTES.login.name}
              element={ROUTES.login.component}
            ></Route>

            <Route
              path={`${ROUTES.register.name}/:id`}
              element={ROUTES.register.component}
            ></Route>
            <Route
              path={ROUTES.imageslider.name}
              element={ROUTES.imageslider.component}
            ></Route>
            <Route
              path={ROUTES.package.name}
              element={ROUTES.package.component}
            ></Route>
            <Route
              path={ROUTES.learntodrive.name}
              element={ROUTES.learntodrive.component}
            ></Route>
            <Route
              path={ROUTES.theorytest.name}
              element={ROUTES.theorytest.component}
            ></Route>
            <Route
              path={ROUTES.instructor.name}
              element={ROUTES.instructor.component}
            ></Route>
            <Route
              path={ROUTES.studentregister.name}
              element={ROUTES.studentregister.component}
            ></Route>
            <Route
              path={ROUTES.bde.name}
              element={ROUTES.bde.component}
            ></Route>
            <Route
              path={ROUTES.g2plus.name}
              element={ROUTES.g2plus.component}
            ></Route>
            <Route
              path={`${ROUTES.g2plusregister.name}/:id`}
              element={ROUTES.g2plusregister.component}
            ></Route>
            <Route
              path={ROUTES.gplus.name}
              element={ROUTES.gplus.component}
            ></Route>
            <Route
              path={`${ROUTES.gplusregister.name}/:id`}
              element={ROUTES.gplusregister.component}
            ></Route>
            <Route
              path={ROUTES.counter.name}
              element={ROUTES.counter.component}
            ></Route>
            <Route
              path={ROUTES.evaluation.name}
              element={ROUTES.evaluation.component}
            ></Route>
            <Route
              path={`${ROUTES.payment.name}/:id`}
              element={ROUTES.payment.component}
            ></Route>
            <Route
              path={ROUTES.sliderText.name}
              element={ROUTES.sliderText.component}
            ></Route>
            <Route
              path={ROUTES.student.name}
              element={ROUTES.student.component}
            ></Route>
            <Route
              path={ROUTES.instructorLogin.name}
              element={ROUTES.instructorLogin.component}
            ></Route>
            <Route
              path={ROUTES.instructorPanel.name}
              element={ROUTES.instructorPanel.component}
            ></Route>
            <Route
              path={ROUTES.acceptedRequest.name}
              element={ROUTES.acceptedRequest.component}
            ></Route>
            {/* <Route
              path={ROUTES.studentList.name}
              element={ROUTES.studentList.component}
            ></Route> */}
            <Route
              path={ROUTES.instructorsList.name}
              element={ROUTES.instructorsList.component}
            ></Route>
            <Route
              path={ROUTES.studentCourses.name}
              element={ROUTES.studentCourses.component}
            ></Route>
            <Route
              path={ROUTES.studentRequest.name}
              element={ROUTES.studentRequest.component}
            ></Route>
            <Route
              path={ROUTES.rejectedRequests.name}
              element={ROUTES.rejectedRequests.component}
            ></Route>
            <Route
              path={ROUTES.guideToDrivingTest.name}
              element={ROUTES.guideToDrivingTest.component}
            ></Route>
            <Route
              path={ROUTES.guideToPracticalTest.name}
              element={ROUTES.guideToPracticalTest.component}
            ></Route>
            <Route
              path={ROUTES.refresherLessons.name}
              element={ROUTES.refresherLessons.component}
            ></Route>
            <Route
              path={ROUTES.femaleInstructor.name}
              element={ROUTES.femaleInstructor.component}
            ></Route>
            <Route
              path={ROUTES.advanceDrivingCourse.name}
              element={ROUTES.advanceDrivingCourse.component}
            ></Route>
            <Route
              path={ROUTES.learnerDriverInsurance.name}
              element={ROUTES.learnerDriverInsurance.component}
            ></Route>
            <Route
              path={ROUTES.obtainLicense.name}
              element={ROUTES.obtainLicense.component}
            ></Route>
            <Route
              path={`${ROUTES.notificationList.name}/:id`}
              element={ROUTES.notificationList.component}
            ></Route>
            <Route
              path={ROUTES.joinOurFleet.name}
              element={ROUTES.joinOurFleet.component}
            ></Route>
            <Route
              path={ROUTES.fleetRegister.name}
              element={ROUTES.fleetRegister.component}
            ></Route>

            <Route
              path={`${ROUTES.test.name}/:id`}
              element={ROUTES.test.component}
            ></Route>
            <Route
              path={`${ROUTES.testModule.name}/:id`}
              element={ROUTES.testModule.component}
            ></Route>
            <Route
              path={ROUTES.guideToBDE.name}
              element={ROUTES.guideToBDE.component}
            ></Route>
            <Route
              path={ROUTES.registerForm.name}
              element={ROUTES.registerForm.component}
            ></Route>
            <Route
              path={ROUTES.lessonSheets.name}
              element={ROUTES.lessonSheets.component}
            ></Route>
            <Route
              path={ROUTES.practiceTest.name}
              element={ROUTES.practiceTest.component}
            ></Route>
            <Route
              path={ROUTES.testResult.name}
              element={ROUTES.testResult.component}
            ></Route>
            {/* <Route
              path={`${ROUTES.testResult.name}/:id`}
              element={ROUTES.testResult.component}
            ></Route> */}
            <Route
              path={ROUTES.adminRegister.name}
              element={ROUTES.adminRegister.component}
            ></Route>
            <Route
              path={ROUTES.adminLogIn.name}
              element={ROUTES.adminLogIn.component}
            ></Route>
            <Route
              path={ROUTES.practiceCategories.name}
              element={ROUTES.practiceCategories.component}
            ></Route>
            <Route
              path={`${ROUTES.practiceModules.name}/:id`}
              element={ROUTES.practiceModules.component}
            ></Route>
            <Route
              path={`${ROUTES.practiceModuleQues.name}/:id`}
              element={ROUTES.practiceModuleQues.component}
            ></Route>
            <Route
              path={ROUTES.instructorForm.name}
              element={ROUTES.instructorForm.component}
            ></Route>
            <Route
              path={ROUTES.bookOnline.name}
              element={ROUTES.bookOnline.component}
            ></Route>
            <Route
              path={ROUTES.termsandconditions.name}
              element={ROUTES.termsandconditions.component}
            ></Route>
          </Route>
          {/* AdminPanel */}
          <Route
            path={ROUTES.adminpanel.name}
            element={ROUTES.adminpanel.component}
          >
            <Route
              path={ROUTES.adminhome.name}
              element={ROUTES.adminhome.component}
            ></Route>
            <Route
              path={ROUTES.admincontact.name}
              element={ROUTES.admincontact.component}
            ></Route>

            <Route
              path={ROUTES.bdeRegister.name}
              element={ROUTES.bdeRegister.component}
            ></Route>
            <Route
              path={ROUTES.bdePackage.name}
              element={ROUTES.bdePackage.component}
            ></Route>
            <Route
              path={ROUTES.g2PlusAdmin.name}
              element={ROUTES.g2PlusAdmin.component}
            ></Route>
            <Route
              path={ROUTES.gPlusAdmin.name}
              element={ROUTES.gPlusAdmin.component}
            ></Route>
            <Route
              path={ROUTES.instructorAdmin.name}
              element={ROUTES.instructorAdmin.component}
            ></Route>
            <Route
              path={ROUTES.location.name}
              element={ROUTES.location.component}
            ></Route>
            <Route
              path={ROUTES.adminInsructorDetail.name}
              element={ROUTES.adminInsructorDetail.component}
            ></Route>
            <Route
              path={ROUTES.g2PlusPackage.name}
              element={ROUTES.g2PlusPackage.component}
            ></Route>
            <Route
              path={ROUTES.gPlusPackage.name}
              element={ROUTES.gPlusPackage.component}
            ></Route>
            <Route
              path={ROUTES.adminStudentDetail.name}
              element={ROUTES.adminStudentDetail.component}
            ></Route>
            <Route
              path={ROUTES.adminStudentPanel.name}
              element={ROUTES.adminStudentPanel.component}
            ></Route>
            <Route
              path={ROUTES.studentUser.name}
              element={ROUTES.studentUser.component}
            ></Route>
            <Route
              path={ROUTES.adminStudentRequest.name}
              element={ROUTES.adminStudentRequest.component}
            ></Route>
            <Route
              path={ROUTES.theorytestcategories.name}
              element={ROUTES.theorytestcategories.component}
            ></Route>
            <Route
              path={ROUTES.questions.name}
              element={ROUTES.questions.component}
            ></Route>
            <Route
              path={`${ROUTES.adminTestQuestions.name}/:id`}
              element={ROUTES.adminTestQuestions.component}
            ></Route>
            <Route
              path={ROUTES.adminTestResult.name}
              element={ROUTES.adminTestResult.component}
            ></Route>
            <Route
              path={ROUTES.adminInstructorPackage.name}
              element={ROUTES.adminInstructorPackage.component}
            ></Route>
            <Route
              path={ROUTES.adminCanadaStates.name}
              element={ROUTES.adminCanadaStates.component}
            ></Route>

            <Route
              path={ROUTES.adminPostCode.name}
              element={ROUTES.adminPostCode.component}
            ></Route>

            <Route
              path={ROUTES.adminCourseCampus.name}
              element={ROUTES.adminCourseCampus.component}
            ></Route>
            <Route
              path={ROUTES.adminTrainingLocation.name}
              element={ROUTES.adminTrainingLocation.component}
            ></Route>
            <Route
              path={ROUTES.adminInsRegistration.name}
              element={ROUTES.adminInsRegistration.component}
            ></Route>
            <Route
              path={ROUTES.adminInstructorRegister.name}
              element={ROUTES.adminInstructorRegister.component}
            ></Route>
            <Route
              path={ROUTES.adminModule.name}
              element={ROUTES.adminModule.component}
            ></Route>
            <Route
              path={ROUTES.adminsList.name}
              element={ROUTES.adminsList.component}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Navigation;
