import React from 'react';
import ROUTES from '../navigations/Routes';
import { Link } from 'react-router-dom';
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <div className="site-footer">
        <div className="container">
          <div className="row">
            <ul className="col-lg-12 list-unstyled d-flex flex-wrap justify-content-center">
              <li className="mr-3 mt-2">
                <Link to={''}>Current Location</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={''}>Blog</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={''}>Disclaimer</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={ROUTES.termsandconditions.name} target='_blank'>Terms & Conditions</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={''}>Privacy Policy</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={''}>Student Testimonial</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={''}>Learner Insurance</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={ROUTES.contact.name}>Contact</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={''}>Support</Link>
              </li>
              <li className="mr-3 mt-2">
                <Link to={ROUTES.adminLogIn.name}>Log In</Link>
              </li>
            </ul>
          </div>
          <div className=" mr-auto col-12">
            <div className="widget">
              <h3 className="mt-3 text-center">Follow Us On</h3>
              <ul className="list-unstyled social d-flex d-inline-block d-flex d-inline-block d-flex justify-content-center">
                <li>
                  <a href="#">
                    <span className="icon-instagram"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="icon-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="icon-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="icon-whatsapp"></span>
                  </a>
                </li>
              </ul>
            </div>
            {/* /.widget --> */}
          </div>
          {/* /.col-lg-3 --> */}
          <div className="row">
            <div className="col-12 text-center">
              <p>
                Copyright &copy; {currentYear}. All Rights Reserved. &mdash;
                <p>
                  Designed & Developed by{' '}
                  <a href="https://whizinfo.com/">
                    <u>Whizinfo Technologies</u>
                  </a>
                </p>
              </p>
            </div>
          </div>
        </div>
        {/* /.container --> */}
      </div>
      {/* /.site-footer --> */}
      {/* <div id="overlayer"></div>
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
    </div>
  );
}
export default Footer;
