import React from 'react';

function TermsAndConditions() {
    return (
        <div className="main">
            <div className="untree_co-section col-lg-12 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12" data-aos="fade-up" data-aos-delay="100">
                            <div className="feature">
                                <div className="row justify-content-center font-s">
                                    <div
                                        className="col-lg-7 text-center"
                                        data-aos="fade-up"
                                        data-aos-delay="0"
                                    >
                                        <h2 className="line-bottom text-center my-4">
                                            CSM Terms and Conditions
                                        </h2>
                                        <h4>
                                            Please read our rules about lessons, fees, cancellations, and instructor responsibilities.
                                        </h4>
                                        <p className='text-justify mt-4 ti-1'>
                                            The following are the terms and conditions (the “Agreement”) which govern your access and use of our online platform through which services may be provided (collectively the “Platform”). The Platform is owned and operated by CSM (CANADIAN SCHOOL OF MOTORING) INC. a/o CSM DRIVING SCHOOL.The Platform may be provided or be accessible via multiple websites or applications whether owned and/or operated by us
                                            or by third parties.
                                            <br />
                                            <br />

                                            By accessing or using the Platform, you are entering into this Agreement. You should read this Agreement carefully before starting to use the Platform. If you do not agree to be bound to any term of this Agreement, you must not access the Platform. When you register with the Platform, we will ask you to expressly agree to these terms of use. If  you do not register, some information and functionality will not be available, but these terms of use still apply. When the terms “we”, “us”, “our” or similar are used in this Agreement, they refer to the Company.
                                            <br />
                                            <br />
                                            For Ontario residents: A ministry-approved BDE Course Provider shall obtain Pupil for the release of personal information (part of the Student Registration form). All Course Providers shall include the following wording for the release of information statement: I certify that the statements in this document are accurate and consent to the release of any information contained herein to the Ministry of Transportation, Insurance Bureau of Canada and the auditing firm retained by the Ministry.

                                        </p>
                                        <h4 className='text-left mt-5'>
                                            Privacy and Security
                                        </h4>
                                        <p className='text-justify mt-4 ti-1'>
                                            Protecting and safeguarding any information you provide through the Platform is extremely important to us. Information about our and privacy practices can be found on our Privacy Policy (The ‘Privacy Policy’).
                                            <br />
                                            <br />

                                            BY AGREEING TO THIS AGREEMENT AND/OR BY USING THE PLATFORM, YOU ARE ALSO AGREEING TO THE TERMS OF THE PRIVACY POLICY. THE PRIVACY POLICY IS INCORPORATED INTO AND DEEMED A PART OF THIS AGREEMENT. THE SAME RULES THAT APPLY REGARDING CHANGES AND REVISIONS OF THIS AGREEMENT ALSO APPLY TO CHANGES AND REVISIONS OF THE PRIVACY POLICY.
                                            <br />
                                            <br />
                                            Licence to use the Platform and documents or other material downloaded from the Platform.
                                        </p>
                                        <p className='text-justify mt-4 '>
                                            Unless otherwise stated, we or our licensors own the intellectual property rights in the Platform and material on and available for download from the Platform.  Subject to the licence below, all these intellectual property rights are reserved.
                                        </p>
                                        <p className='text-justify mt-4 '>
                                            Except as expressly stated in these Terms of Use, we reserve all rights to all materials on the Platform, including, without limitation, written content, graphical and design elements (including the Platform’s “look and feel”), and service marks and trademarks. By submitting material to any area of the Platform, you automatically grant, or warrant that the owner of such material has expressly granted, to us the royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or hereafter developed for the full term of any copyright that may exist in such material. You may not sell, transfer or assign any products or services or your rights to any products or services provided by us to any third party without our express written authorization. You may not copy, modify, alter, or excerpt (or encourage or help others to copy, modify, alter, or excerpt) the Platform materials. You may not use (or encourage or help others to use) the Platform for any purpose or in any manner that is prohibited by these Terms of Use or by applicable law. You acknowledge and agree that these Terms of Use in no way convey any right, title or interest to you in any materials on the Platform, including, without limitation, any statutory or common law copyright, patent or other intellectual property right in such materials.
                                        </p>
                                        <p className='text-justify mt-4 '>
                                            You may view, download for caching purposes only, print pages, download PDF documents and other electronic files which are specifically offered within the content pages from the Platform for your own personal use, subject to the restrictions set out below and elsewhere in this Agreement.
                                        </p>
                                        <h4 className='text-left mt-5'>
                                            For Ontario Pupil only:
                                        </h4>
                                        <p className='text-justify mt-3'>
                                            I certify that the statements provided are accurate and consent to the release of any information contained herein to the Ministry of Transportation, Insurance Bureau of Canada and the auditing form retained by the Ministry of Transportation.
                                        </p>
                                        <h4 className='text-left mt-5'>
                                            Acceptable Use
                                        </h4>
                                        <p className='text-justify mt-3'>
                                            You must not use our Platform in any way that causes, or may cause, damage to the Platform or impairment of the availability or accessibility of the Platform; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
                                        </p>
                                        <p className='text-justify mt-3'>
                                            You must not use our Platform to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
                                        </p>
                                        <p className='text-justify mt-3'>
                                            You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to our Platform without our express written consent.
                                        </p>
                                        <p>
                                            <ol className='mainOL' type='1'>
                                                <li >

                                                    <h4>These Terms</h4>
                                                    <ol type="i">
                                                        <li>What these terms cover. These terms cover your use of the CSM Driving School website or app (our “Website/App”) and on which we enable you to book driving tuition services with third-party instructors and make payments for driving tuition (“Website/App Services”).</li>
                                                        <li>Why you should read them. These terms, together with our Privacy Notice and Cookie Policy, constitute this “Agreement” and tell you who we are, how we provide the Website/App Services, how each of us can change or end this Agreement, what to do if there is a problem and other important information.</li>
                                                        <li>Your agreement for driving tuition is with your instructor. The contract for driving tuition is solely between you and your driving instructor and is separate to this Agreement. Your driving instructor is responsible for any obligations relating to the provision of driving tuition (including for bookings made using our Website/App).</li>
                                                        <li>Our agreement with you for Website/App Services. This Agreement is between you and CSM Driving School and is for the Website/App Services which enable you to book driving tuition services with third-party instructors and on which we receive payments from you.</li>
                                                        <li>Acceptance of this Agreement. By using the Website/App Services, you agree to be bound by this Agreement to the exclusion of all other terms and conditions. You also acknowledge and agree that we may process your personal data in accordance with our Privacy Policy and Cookie Policy (which are subject to change from time to time). If you do not accept the terms of this Agreement, please do not use our Website/App.</li>
                                                    </ol>
                                                </li>

                                                <li>We act as agent for your instructor
                                                    <ol type="i">
                                                        <li >Your instructor. Your instructor operates their own driving tuition business as a self-employed.


                                                        </li>
                                                        <li >We, .CSM (CANADIAN SCHOOL OF MOTORING INC. a/o CSM DRIVING SCHOOL) both act as agent for your instructor in providing the Website/App Services, including where we:
                                                            <ol type="a">

                                                                <li >enable you to make bookings for driving tuition with your instructor;</li>
                                                                <li >supply any information or documentation to you; and/or</li>
                                                                <li >process any payments for your lessons.</li>
                                                            </ol>
                                                        </li>

                                                    </ol>
                                                </li>
                                                <li>website/App Services
                                                    <ol type="i">
                                                        <li>Our Website/App Services. When you use our Website/App, we’ll let you know about the availability of an instructor operating in your area and their fees.
                                                        </li>
                                                        <li>Responsibility for matters relating to lessons and tests. In running their own business, your instructor is solely responsible for all matters relating to the provision of driving tuition under their separate contract with you. This means that you and your instructor will need to agree all matters relating to timing, location and content of driving lessons and tests.</li>
                                                        <li>Eligibility for booking lessons. Lessons can only be booked using our Website/App for an individual who meets ALL of the following criteria:
                                                            <ol type="a">
                                                                <li>aged 17 or over, or aged 16 or over and receive the higher rate of Disability Living Allowance (mobility component); and</li>
                                                                <li>holds a valid Canadian provisional driving licence, full Canadian Provinces License licence or overseas driving licence; and</li>
                                                                <li>is legally entitled to drive in Canada.</li>
                                                            </ol>
                                                        </li>
                                                        <li>Licence. On your first lesson you must present your instructor with a valid driving licence. We may also ask you to provide details of your licence to us so that we can verify its validity.</li>
                                                        <li>Entitlement to drive. You must notify your instructor of any matters which affect your ability or entitlement to have driving tuition, including (without limitation) any lack of or loss of a valid Canadian Provinces  licence or other licence referenced in Paragraph.</li>
                                                        <li>What happens if you do not give required information to us. We will ask you to provide certain information to us or your instructor (as applicable). If you do not provide this within a reasonable time or if you provide incomplete or incorrect information, then we may either end this Agreement (and Paragraph 12 will apply) or charge you a reasonable additional sum for extra work required as a result. We are not responsible for any failures or delays caused by you not giving us information we need within a reasonable time of us asking for it.</li>
                                                        <li>We are not responsible for delays outside our control. If any Website/App Services are delayed by an event outside of the control of CSM, then we will contact you as soon as possible and take steps to minimise the effect of the delay. Provided we do this then we will not be liable for delays caused by the event, but if there is a risk of substantial delay then you may contact us to end this Agreement and receive a refund for any sums you have paid in advance for tuition not already received.</li>
                                                        <li>Our rights to suspend the Website/App Services. We may have to suspend the Website/App Services to:
                                                            <ol type="a">
                                                                <li>deal with technical problems or make minor technical changes</li>
                                                                <li>update the Website/App Services to reflect changes in relevant laws and regulatory requirements; and/or</li>
                                                                <li>make changes to the Website/App Services as requested by you or notified by us to you.</li>
                                                            </ol>
                                                        </li>
                                                        <li>Your rights if we suspend the Website/App Services. We will contact you in advance to tell you we will be suspending the Website/App Services, unless the problem is urgent or an emergency. You may contact us to end this Agreement if we suspend it, or tell you that we are going to suspend it, for a period of more than 30 days and we will refund any sums you have paid in advance for tuition not already received.</li>
                                                        <li>We may also suspend the Website/App Services if you do not pay. If you do not pay us when you are supposed to (see Paragraph 5) and you still do not make payment within 14 days of us reminding you that payment is due, we may suspend supply of the Website/App Services and you will not be entitled to associated driving tuition until you have paid us the outstanding amounts.</li>
                                                    </ol>
                                                </li>
                                                <li>Payment and lesson bookings
                                                    <ol type="i">
                                                        <li >Fees. The “Fees” are the sum(s) which you agree to pre-pay for driving lessons and which we (CSM (CANADIAN SCHOOL OF MOTORING) INC. a/o CSM DRIVING SCHOOL ) collect as an agent of your instructor. The Fees include HST and are calculated in CA $ unless stated otherwise. The Fees will be shown on the booking page on our Website/App when you book your driving lesson(s), provided that additional Fees may be due if certain events occur (please see Paragraph 6).
                                                        </li>
                                                        <li >Topping up your account. You may top up your account by pre-paying additional funds to purchase additional driving lessons. You may also request a refund of pre-paid amounts for driving tuition not yet provided, subject to any applicable late cancellation fees (please see Paragraphs 8 and 9 for further details).
                                                        </li>
                                                        <li >Minimum number of lessons. You may be required to pre-pay for a minimum number of lessons as notified to you on our Website/App during the booking process.
                                                        </li>
                                                        <li >Booking fee. The Fees include a booking fee (as notified to you on our Website/App before you make a payment) on the purchase price of your initial lesson package, and all future top-ups.
                                                        </li>
                                                        <li >Payment method. If you book driving lessons using our Website/App Services then you must pay using the payment methods specified on our Website/App from time to time. We cannot accept other forms of payment and neither we nor any CSM Driving School group company have any responsibility or liability for payments made by any other means (including if you pay your instructor directlysee Paragraph 5.10).
                                                        </li>
                                                        <li >Third party payment platform. All payments are made via a third party payment platform that may change from time to time. You will be notified of the third party payment platform at the time your payment information is required, so please read any applicable third party terms carefully. You confirm that the payment card you use is yours or that you have been specifically authorised to use it to purchase the driving lesson(s)
                                                        </li>
                                                        <li >Payment data. We do not collect, hold or process your payment details at any time throughout the booking of your Service and your payments will be subject to the terms and conditions of the third party payment platform.
                                                        </li>
                                                        <li >When payment is due. You must pre-pay the Fees due for driving tuition at least 48 hours before the start of the lesson.
                                                        </li>
                                                        <li >What happens if we get the price wrong? It is possible that, despite our best efforts, some of the driving lessons may be incorrectly priced. If the correct price at your booking date is higher than the price stated to you, we will contact you for your instructions before we accept your booking. If we accept and process your booking where a pricing error is obvious and unmistakable and could reasonably have been recognised by you as a mispricing, we may end this Agreement and/or cancel associated booking(s) and will refund you any sums you have pre-paid for tuition not yet received.
                                                        </li>
                                                        <li >Payments to instructors. We do not accept any responsibility for any payments made directly to instructors or any other third parties (whether for booking additional lessons or otherwise).
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Price changes
                                                    <ol type="i">
                                                        <li >Price validity. The Fees for prepaid tuition are based on the lesson price at the time when your booking is confirmed and except as outlined in Paragraph 6.2, the lesson price will be valid for 6 months after that date. Any unused lessons remaining after 6 months have elapsed will be subject to any lesson price increase applied prior to the lessons being taken which shall become payable by you.
                                                        </li>
                                                        <li >Adjustments to pre-paid amounts due. Where you've prepaid, you'll either be required to pay any additional amounts beyond the cost of your prepaid lessons or receive a refund of some of your prepayments (as applicable) as follows:
                                                            <ol type="a">

                                                                <li >If you’ve moved to a postal code area and requested re-allocation to another instructor because your current instructor doesn’t operate in the new area, you’ll be required to cover any difference in the hourly rate between those instructors if your new instructor charges a higher hourly rate. If your instructor charges a lower rate, you’ll be refunded the difference.</li>
                                                                <li >If you’ve requested to switch instructors within the same postal code area and your new instructor charges a higher hourly rate than your previous instructor, you’ll be required to pay the difference in the hourly rate. If your instructor charges a lower rate, you’ll receive a refund of the difference.</li>
                                                                <li >If you’ve requested to switch instructors in the same postal code area and your new instructor charges a supplement (such as an additional fee for tuition in a car with automatic transmission), you’ll be required to pay any such supplement.</li>
                                                                <li >If you’ve purchased lessons with a trainee instructor and, at your request, you’re re-allocated to a fully qualified instructor you’ll be required to pay the difference in the hourly rate between the trainee and the qualified instructor.</li>
                                                            </ol>
                                                        </li>

                                                    </ol>
                                                </li>
                                                <li>Special offers and discounts
                                                    <ol type="i">
                                                        <li >Special offers. From time to time, we may provide offers and discounts for new and existing learner pupils. Additional terms and conditions apply and we reserve the right to introduce and withdraw offers from time to time without notice. All offers remain subject to availability.
                                                        </li>
                                                        <li >Introductory offers. Introductory offers are only available to new learner pupils who have not previously booked lessons with either CSM Driving School or CSM Driving School and are only applied to a pupil’s very first booking. They can't be used in conjunction with other offers and aren't transferable.
                                                        </li>

                                                    </ol>
                                                </li>
                                                <li>Changes to bookings
                                                    <ol type="i">
                                                        <li >If you want to cancel a booking. You must give your instructor a minimum of 48 hours’ prior notice of cancellation for any driving lesson. If you are unable to speak with your instructor, you are able to cancel lessons using our Website/App provided that you are giving 48 hours’ prior notice. Our Pupil' Service Team are also on hand to help via our Live Chat or phone call..
                                                        </li>
                                                        <li >What happens if you don’t give 48 hours’ notice.
                                                            If you don’t give at least 48 hours’ notice of cancellation before the lesson start time then you’ll be charged for those lesson(s) in full, provided that this does not affect any rights you may have to cancel this Agreement under the statutory 10-day cooling off period (see Paragraph 11.3)
                                                        </li>
                                                        <li >Cancellation of driving tests. Drive Test (Licensed by the Government of Ontario) requires you to give three clear working days to cancel your test. This may mean that you may lose your DriveTest  fee if don’t cancel it in time and/or if your instructor says you’re not ready for your test within this three-day notice period.
                                                        </li>
                                                        <li >If you instructor cancels a lesson. If your instructor cancels a lesson then they’ll endeavour to rearrange the lesson with you. If they don’t do this then please contact them or you can re-schedule the lesson time using our Website/App. If you have any issues please contact us using our Live Chat or phone call.
                                                        </li>
                                                        <li >Replacement instructors. Your instructor may arrange for a replacement driving instructor to provide your lesson and they will let you know the name of the replacement instructor in advance of the lesson. If you do not wish to undertake the lesson with the replacement instructor then please let your main instructor know so that they can make other arrangements.
                                                        </li>
                                                        <li >We may need to make changes to a booking. There may be occasions where we will need to change or cancel a booking. If this occurs, we will let you know and endeavour to re-book your lessons at your earliest convenience.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Refunds
                                                    <ol type="i">
                                                        <li >Statutory rights. Nothing in this Paragraph 9 will affect any statutory rights to a refund that you may have as a consumer.
                                                        </li>
                                                        <li >Refunds of amounts pre-paid to us. You can request a full refund of amounts pre-paid to us for unused driving tuition using the Website/App Services, subject to the following:
                                                            <ol type="a">
                                                                <li >You won’t be eligible for a refund on any lessons which have already been taken at the time of cancellation (including the booking fee);</li>
                                                                <li >If you cancel pre-paid tuition within 48 hours of the lesson time then you won’t be eligible for a refund on those lessons (including the booking fee);</li>
                                                                <li >Where possible, we’ll refund you using the same method you used to pay for your lessons. If for any reason we’re not able to do this, we’ll refund you by any other method we think is appropriate;</li>
                                                                <li >We may request additional information from you to confirm your identity to comply with the Money Laundering Regulations 2007. We’ll use this information to make sure we follow our Merchant Operating Instructions for card collection facilities;</li>
                                                                <li >Refunds may take up to 10 working days to reach you or your account;</li>
                                                                <li >Refund of partial block bookings will be made pro rata on any lessons not taken, provided that if any block bookings were subject to a discount based on a minimum number of lesson bookings then any lessons already taken will be charged at non-discounted pricing if you have not met the minimum booking criteria for the offer; and</li>
                                                                <li >in the event of a ‘buy one, get one free’ or similar offer, no refund will take place once the first lesson has been taken.</li>
                                                            </ol>
                                                        </li>
                                                        <li >Refunds of amounts paid to your instructor directly. If you’ve paid your instructor directly for the tuition that you want to cancel, then you should speak to your instructor as we have no responsibility for those direct payments (see Paragraph 5.10 for more information).
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Our rights to make changes
                                                    <ol type="i">
                                                        <li >Changes to these terms. We may change the terms of this Agreement at any time by publishing modified terms on our Website/App. Your use of our Website/App after a change has been posted will be deemed to signify your acceptance of the modified terms of this Agreement. We recommend you print a copy of this Agreement and retain a copy for your records.
                                                        </li>
                                                        <li >We may need to make changes to a booking or the Website/App Services. There may be occasions where we will need to change or cancel a booking (see Paragraph 8.6) or make changes to the Website/App Services.
                                                        </li>
                                                        <li >If you are not happy with changes. If you are not happy with any change we have made to a booking, the Website/App Services or this Agreement then you can contact us to cancel your booking(s) and receive a refund for any driving tuition paid for but not received.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Your rights to end this agreement
                                                    <ol type="i">
                                                        <li >You can always end your Agreement with us. Your rights when you end this Agreement will depend on what you have purchased, how we are performing and when you decide to end this Agreement:
                                                            <ol type="a">
                                                                <li >If what you have bought is faulty or misdescribed, you may have a statutory right to end this Agreement, to get the service re-performed or to get some or all of your money back;</li>
                                                                <li >If you want to end this Agreement because of something we have done or have told you that we are going to do, see Paragraph 11.2;</li>
                                                                <li >If you are a consumer and have just changed your mind about the services, see Paragraph 11.3. You may be able to get a refund if you are within the 14-day cooling-off period, but this may be subject to deductions.</li>

                                                            </ol>
                                                        </li>

                                                        <li >Ending this Agreement because of something we have done or are going to do. If you are ending this Agreement for a reason set out at Paragraphs 11.2.1 to 11.2.5 below then this Agreement will end immediately, and we will refund you in full for any driving tuition which has not been provided. The reasons are:
                                                            <ol type="a">
                                                                <li >we have told you about an upcoming change to the Website/App Services or these terms which you do not agree to (see Paragraph 10);</li>
                                                                <li >we have told you about an error in the Fees or description of driving tuition services and you do not wish to proceed;</li>
                                                                <li >there is a risk that supply of the Website/App Services may be significantly delayed because of events outside our control;</li>
                                                                <li >we have suspended supply of the Website/App Services for technical reasons, or notify you that we are going to suspend them for technical reasons, in each case for a period of more than 30 days; or</li>
                                                                <li >you have a legal right to end this Agreement because of something we have done wrong.</li>
                                                            </ol>
                                                        </li>
                                                        <li >Exercising your right to change your mind if you are a consumer (14-day cooling off period). If you are a consumer then where you book and pay for driving tuition using the Website/App Services, you may have a statutory right to cancel this Agreement within 14 days and receive a refund.
                                                        </li>
                                                        <li >When does the cooling off period start? If you are a consumer then the 14-day cooling off period starts when you enter into this Agreement, which is the earlier of when you first book or pre-pay for driving tuition using the Website/App Services.
                                                        </li>
                                                        <li >When consumers do not have the right to change their minds. You do not have a right to change your mind in respect of services (including driving tuition) once they have been performed, even if the cancellation period is still running. This means that if you cancel then you will not be entitled for a refund for any lessons provided up until the time you tell us that you have changed your mind.
                                                        </li>
                                                        <li >How we will refund consumers. If you are a consumer entitled to a refund under the 14-day statutory cooling off period then we will refund you amounts pre-paid for driving tuition by the same method you used for payment within 10 working days of you telling us that you wish to cancel this Agreement and want a refund, provided that we may deduct Fees due for services already provided when you told us that you had changed your mind
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Our rights to end this agreement
                                                    <ol type="i">
                                                        <li >We may end this Agreement if you break it. We may end this Agreement at any time by writing to you if:
                                                            <ol type="a">
                                                                <li >you do not make any payment to us when it is due and you still do not make payment within 7 days of us reminding you that payment is due; or</li>
                                                                <li >you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the Website/App Services; or</li>
                                                                <li >if you otherwise break the terms of this Agreement.</li>
                                                            </ol>
                                                        </li>

                                                        <li >You must compensate us if you break this Agreement. If we end this Agreement in the situations set out in Paragraph 12.1 then we will refund any money you have pre-paid for lessons that have not been taken, but we may deduct or charge reasonable compensation for the net costs we will incur as a result of your breaking this Agreement.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Transferability of lessons or pre-payments
                                                    <ol type="i">
                                                        <li >You can't sell or transfer lessons which have been purchased in your name (or any lesson pre-payments) to any other person.
                                                        </li>
                                                    </ol>
                                                </li>

                                                <li>Fraud prevention
                                                    <ol type="i">
                                                        <li >Valid lessons. Your lessons are only valid if they’re paid for using the methods outlined in Paragraph 5.5 above and otherwise in accordance with these terms. If they’ve been purchased through another source then please contact us immediately using our Live Chat or phone call..
                                                        </li>
                                                        <li >We can suspend or cancel lessons that were not purchased correctly. If we determine that any of your lessons weren’t purchased or pre-paid for in accordance with this Agreement, we reserve the right to suspend or cancel these lessons in your account in the Website/App Services without notice and neither we nor your instructor will have any obligation or liability in connection with any payments made for those lessons.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Our responsibility for loss and damage
                                                    <ol type="i">
                                                        <li >We are not responsible for certain losses.
                                                            <ol type="a">
                                                                <li >Losses arising from the driving tuition. As we only provide the Website/App Services, we (CSM Driving School) are not responsible for any loss or damage arising from any driving tuition provided by your instructor as this is performed under a separate contract between you and your instructor.</li>
                                                                <li >Unforeseeable loss and damage. Loss or damage is not foreseeable, it was not obvious that it would happen and nothing you said to us before we accepted your booking meant we should have expected it.</li>
                                                                <li >Losses caused by a delaying event outside our control. As long as we comply with the steps set out in Paragraph 4.7.</li>
                                                                <li >Losses which are avoidable. This includes losses caused by you or which you could have avoided by taking reasonable action (including by following our reasonable instructions relating to the Website/App Services).</li>
                                                                <li >Business losses. This includes any loss or damage relating to any business interest that you (or anyone else using the Website/App Services) may have, including without limitation any loss suffered in connection with your trade, business, craft or profession, loss of revenue, loss of profit, loss of business, loss of anticipated savings, business interruption, loss of goodwill or reputation, or loss of business opportunity whether arising in contract, tort (including negligence), breach of statutory duty, or otherwise.</li>
                                                            </ol>
                                                        </li>
                                                        <li >We do not exclude or limit in any way our liability where it would be unlawful to do so. We do not exclude our liability for:
                                                            <ol type="a">
                                                                <li >death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors;</li>
                                                                <li >fraud or fraudulent misrepresentation;</li>
                                                                <li >any other matter in respect of which it would be unlawful for us to exclude or restrict liability.</li>
                                                            </ol>
                                                        </li>
                                                        <li >Limitation on our liability. Subject to Paragraphs 15.1 and 15.2, the most that we (CSM Driving School) will pay you in relation to losses that you incur under this Agreement is limited.
                                                        </li>
                                                        <li >Statutory rights. Nothing in this Agreement will affect any statutory rights you may have as a consumer.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Insurance
                                                    <ol type="i">
                                                        <li >Your instructor will have appropriate motor vehicle insurance which will provide cover should you be involved in a collision as a learner driver whilst in control of driving the instructor’s tuition vehicle.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Short notice test package
                                                    <ol type="i">
                                                        <li >What is a short notice test? A short notice test is when there’s two weeks or less from the date of the first planned lesson to the date of your booked test.
                                                        </li>
                                                        <li >Short notice test packages. A short notice test package consists of five hours of instructor and tuition vehicle time as a minimum. This includes a two-hour driving assessment and a further three hours covering the day of your driving test. As a minimum, you’ll need to pay for five hours of tuition before any lessons can be booked. Additional hours bought within the short notice test period will be charged at the instructor’s standard hourly tuition rate plus..
                                                        </li>
                                                        <li >If you fail your test. If you fail your test and need more lessons, these will be charged at your instructor’s standard hourly tuition rate.
                                                        </li>
                                                        <li >Cancelling a short notice test package. You must give your instructor a minimum of 48 hours’ prior notice of cancellation - please see Paragraph 8 (Changes to Bookings) and 9 (Refunds) for further information.
                                                        </li>
                                                        <li >Initial assessment. During the initial two-hour assessment lesson, you’ll be evaluated by your instructor. If, after this, the instructor tells you that you’re not ready for the test, they’re under no obligation to take you. Your instructor will advise you of the next steps needed to become test ready and this may mean that you have to purchase additional driving lessons.
                                                        </li>
                                                        <li >Rescheduling your test. The Drive Test (Licensed by the Government of Ontario) requires you to give three clear working days to cancel or re-schedule your test. This means that you may lose your Drive Test (Licensed by the Government of Ontario) test fee if your instructor says that you’re not ready for the test after the initial two-hour assessment lesson. In these circumstances, neither we nor your instructor are liable for the cost of any lost driving test fee.
                                                        </li>
                                                        <li >Unused pre-paid tuition. If you decide to reschedule your test to a later date, you can use the unused pre-paid hour(s) for further tuition toward the new test date.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Your account
                                                    <ol type="i">
                                                        <li >Account credentials. On registering with our Website/App, you are issued with access credentials which must be used to access your account. Your access credentials are personal to you and are not transferable. You must not authorise or permit any other person to use your account. You must take reasonable care to protect and keep confidential your access credentials and other account or identity information. You must notify us immediately of any apparent breach of security such as loss, theft, misuse or unauthorised disclosure or use of any access credentials.
                                                        </li>
                                                        <li >Responsibility for content. Your access credentials are the method used by us to identify you and are very important. You are responsible for all information posted on our Website/App by anyone using your username and password and any payments due as a result of the use of the Website/App Services or our Website/App by anyone using your username and password. You may not adapt or circumvent any systems in place in connection with our Website/App
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Third party websites
                                                    <ol type="i">
                                                        <li >Links to third party websites. Our Website/App may contain links or reference to third party websites, which include third party goods and services. We have no control over these third party websites and therefore assume no responsibility of any content, material or information contained in them or any goods and services offered via them.
                                                        </li>
                                                        <li >Use of third party websites. The inclusion of a hyperlink or reference to a third party website does not constitute an endorsement of such third party’s website, products or services. Your use of any third party website may be governed by the terms and conditions of that third party website and you should read these carefully before using the third party website.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Our responsibility for this Website
                                                    <ol type="i">
                                                        <li >Errors in our Website/App. We do not guarantee that our Website/App will be uninterrupted or fault free, that defects will be corrected or that our Website/App or that the service that makes them available are free from viruses, errors or anything else which may have a harmful effect on any technology.
                                                        </li>
                                                        <li >Suspension of our Website/App. We may, without notice, suspend our Website/App for repair, maintenance, improvement or for any other reason whatsoever.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Intellectual property rights
                                                    <ol type="i">
                                                        <li >Who owns the materials on this Website. All materials presented on our Website/App, unless specifically indicated otherwise, are under owned by us or our licensors and we reserve all rights in any such materials (including copyright, trademarks, registered designs, design rights, domain names, database rights, patents, trade names and all other intellectual property rights). You can download or print a single copy of the contents on our Website/App, but you may not reproduce or redistribute materials, in whole or in part, on this Website without our prior written permission.
                                                        </li>
                                                        <li >Who owns brands and logos on this Website. All CSM Driving School trademarks, brands and logos used on this Website are the registered trademark of CSM Driving School Brand Management Limited and are used under licence. You have no right to use, replicate or produce any of the CSM Driving School trademarks, brands or logos, other than as expressly detailed in this Paragraph 21.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Data submitted by you
                                                    <ol type="i">
                                                        <li >Your ownership of content you upload. You retain ownership of copyright in content that you submit to our Website/App. You grant us a world-wide, non-exclusive, royalty free, non-terminable licence to use, copy, distribute, publish and transmit such content in any manner and for any purpose.
                                                        </li>
                                                        <li >We accept no liability for content or data supplied by any user for display on our Website/App. If you submit content or data for display on our Website/App then you are responsible for ensuring that the content or data is accurate, complete and up to date and for updating that content or data where necessary. This is to ensure that no content or data is uploaded or submitted which is untrue, defamatory, obscene, abusive or otherwise objectionable or in breach of any applicable laws or rights of third parties. You warrant that you have taken all reasonable precautions to ensure that any content and data you upload or otherwise submit to our Website/App is free from viruses and anything else which may have a contaminating or destructive effect on any part of our Website/App or any other technology.
                                                        </li>
                                                        <li >We can remove material. We reserve the right (without limiting our rights to seek other remedies) to remove offending material placed on our Website/App that we consider to constitute a misuse of our Website/App or which is otherwise harmful to other users of our Website/App.
                                                        </li>

                                                    </ol>
                                                </li>
                                                <li>Other important terms
                                                    <ol type="i">
                                                        <li >We may transfer this Agreement to someone else. We may transfer all or part of our rights or obligations under this agreement to someone else, provided that we will take reasonable steps to ensure that your rights under this agreement are not prejudiced.
                                                        </li>
                                                        <li >You need our consent to transfer your rights to someone else. You may only transfer your rights or obligations under this Agreement with our prior written consent.
                                                        </li>
                                                        <li >Nobody else has any rights under this Agreement (except our affiliates).
                                                        </li>
                                                        <li >If a court finds part of this Agreement illegal, the rest will continue in force. If any provision of this Agreement is held to be unlawful, invalid or unenforceable, that provision shall be deemed severed and the validity and enforceability of the remaining provisions of this Agreement shall not be affected.
                                                        </li>
                                                        <li >Effect of termination. If this Agreement ends whether by expiry or termination then this will not affect our right to receive any money which you owe to us under it.
                                                        </li>
                                                        <li >Even if we delay in enforcing this Agreement, we can still enforce it later. Our failure to enforce or non-reliance on any of the terms and conditions of this Agreement on a particular occasion or occasions will not prevent us from subsequently relying on or enforcing them.
                                                        </li>
                                                        <li >Headings and interpretation. The headings used in the terms and conditions of this Agreement are for convenience only and shall not affect the interpretation of their contents. Any words following the terms “including”, “in particular”,
                                                            “for example” or any similar expression are illustrative and will not limit the sense of the words preceding those terms.

                                                        </li>
                                                        <li >Which laws apply and where can you bring proceedings. These terms are governed by the law of Ontario. 
                                                        </li>
                                                        <li >Where we may send notices. We may send all notices under this Agreement by email to the most recent email address you have supplied to us.
                                                        </li>

                                                    </ol>
                                                </li>
                                                <li>Customer care and complaints
                                                    <ol type="i">
                                                        <li >Speaking to your instructor. In the event of any problem arising with the driving tuition, you should speak to your instructor as they provide driving tuition to you under a separate contract. We recommend that you don’t take any further lessons with that instructor until the matter is resolved.
                                                        </li>
                                                        <li >If you want to change instructor. If you would like to change to a different instructor then you can do this via our Website/App or by contacting us using our Live Chat or phone call.
                                                        </li>
                                                        <li >How to tell us about problems with the Website/App Services. If you have any questions or complaints about the Website/App Services, please contact our Pupil Service Team via our Live Chat or phone call.
                                                        </li>
                                                        <li >Our complaints department. If our Pupil Service Team isn’t able to resolve your complaint, you can contact our Complaints department by email or by post using the addresses below. We may require evidence of any financial transactions and lesson times before we’re able to investigate. You should therefore keep your Driver Record (given to you by your instructor) details accurate and up to date.
                                                        </li>

                                                    </ol>
                                                </li>

                                            </ol>


                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TermsAndConditions;